import React, { FC } from 'react';
import { ChatHeaderProps } from './interfaces';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CloseSmall } from '@wix/wix-ui-icons-common/on-stage';

import s from './ChatHeader.scss';

export const ASSISTANT_HEADER_HOOKS = {
  close: 'close-ai-assistant',
};

export const ChatHeader: FC<ChatHeaderProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <div className={s.headerLine}>
        <span className={s.headerAi}>{t('ai_assistant_header_1')}</span>
        <span>{t('ai_assistant_header_2')}</span>
      </div>
      <div>
        <CloseSmall
          width={16}
          height={16}
          onClick={onClose}
          className={s.closeIcon}
          data-hook={ASSISTANT_HEADER_HOOKS.close}
        />
      </div>
    </div>
  );
};
