import React, { FC, useMemo } from 'react';
import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa/cssVars';
import { SingleChoiceButtonsProps } from './interfaces';

import { classes } from './SingleChoiceButtons.st.css';

export const SingleChoiceButtons: FC<SingleChoiceButtonsProps> = ({
  options,
  onSubmit,
}) => {
  const stringifiedOptions = useMemo(
    () => options.value?.map((val) => `${val}`),
    [options],
  );

  const submitUserMessage = (option: string) => {
    onSubmit(option);
  };

  return (
    <div className={classes.container}>
      {stringifiedOptions.map((option) => (
        <Button
          size={ButtonSize.small}
          style={{ minWidth: '150px' }}
          wrapContent
          onClick={() => submitUserMessage(option)}
          upgrade
          priority={ButtonPriority.secondary}
        >
          {option}
        </Button>
      ))}
    </div>
  );
};
