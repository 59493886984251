import React, { FC } from 'react';
import { ProgressBarProps } from './interfaces';
import { ProgressBar as ProgressBarTPA, Text } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes } from './ProgressBar.st.css';

export const ProgressBar: FC<ProgressBarProps> = ({ total, completed }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.firstLine}>
        <Text>{t('ai_assistant_progress')}</Text>
        <Text>{`${completed}/${total}`}</Text>
      </div>
      <ProgressBarTPA max={total} value={completed} />
    </div>
  );
};
