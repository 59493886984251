import React, { FC, useState } from 'react';
import { ChatInputProps } from './interfaces';
import { TextField } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import SendIcon from '../../../../../../assets/images/send-icon.svg';

import styles from './ChatInput.scss';

export const CHAT_INPUT_HOOKS = {
  userInput: 'assistant-chat-user-input',
  sendIcon: 'assistant-chat-send-icon',
};

export const ChatInput: FC<ChatInputProps> = ({ onSubmit, disabled }) => {
  const [value, setValue] = useState<string>('');
  const { t } = useTranslation();

  const submitValue = () => {
    onSubmit(value);
    setValue('');
  };

  const submitValueByEnter: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === 'Enter' && !disabled) {
      submitValue();
    }
  };

  const changeValue: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = (event.target as HTMLInputElement).value;
    setValue(inputValue);
  };

  return (
    <div>
      <TextField
        className={styles.textField}
        data-hook={CHAT_INPUT_HOOKS.userInput}
        suffix={
          <div
            onClick={submitValue}
            className={styles.sendIcon}
            data-hook={CHAT_INPUT_HOOKS.sendIcon}
          >
            <SendIcon />
          </div>
        }
        placeholder={t('ai_assistant_input_placeholder')}
        onKeyDown={submitValueByEnter}
        value={value}
        onChange={changeValue}
      />
    </div>
  );
};
