import { FormValues } from '@wix/form-viewer';

export function getChangedValueTargetsForBi(
  oldFormData: FormValues,
  newFormData: FormValues,
): string[] {
  const keys = Object.keys(oldFormData);
  const targets = keys.reduce((changedTargets, target) => {
    const newValue = newFormData[target];
    const oldValue = oldFormData[target];

    if (
      newValue === oldValue ||
      JSON.stringify(newValue) === JSON.stringify(oldValue)
    ) {
      return changedTargets;
    }

    return [...changedTargets, target];
  }, []);
  return targets;
}
