import React, { FC } from 'react';
import { ChatActionsProps } from './interfaces';
import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa/cssVars';
import EndChatIcon from '../../../../../../assets/images/end-chat.svg';
import SkipQuestionIcon from '../../../../../../assets/images/skip-question.svg';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes } from './ChatActions.st.css';

export const ACTIONS_HOOKS = {
  skipButton: 'chat-actions-skip-button',
  submitButton: 'chat-actions-submit-button',
};

const commonButtonProps = {
  priority: ButtonPriority.basicSecondary,
  size: ButtonSize.tiny,
  style: {
    height: '10px',
  },
};

export const ChatActions: FC<ChatActionsProps> = ({
  onClose,
  submitUserMessage,
  enableSkip,
  enableSubmit,
  submitForm,
}) => {
  const { t } = useTranslation();

  const skipQuestion = () => {
    submitUserMessage(t('ai_assistant_skip_message'));
  };

  return (
    <div className={classes.actionsContainer}>
      <Button {...commonButtonProps} onClick={onClose}>
        <div className={classes.buttonContent}>
          <EndChatIcon /> {t('ai_assistant_actions_end')}
        </div>
      </Button>
      {enableSkip && (
        <Button
          data-hook={ACTIONS_HOOKS.skipButton}
          className={classes.skipQuestionButton}
          {...commonButtonProps}
          onClick={skipQuestion}
        >
          <div className={classes.buttonContent}>
            <SkipQuestionIcon /> {t('ai_assistant_actions_skip')}
          </div>
        </Button>
      )}
      {enableSubmit && (
        <Button
          data-hook={ACTIONS_HOOKS.skipButton}
          className={classes.submitButton}
          {...commonButtonProps}
          onClick={submitForm}
        >
          {t('ai_assistant_actions_submit')}
        </Button>
      )}
    </div>
  );
};
