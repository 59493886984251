import React, { FC, useState, useMemo } from 'react';
import { MultiChoiceButtonsProps } from './interfaces';
import { Checkbox, CheckboxGroup } from 'wix-ui-tpa';
import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-bm';

import { classes } from './MultiChoiceButtons.st.css';

export const MultiChoiceButtons: FC<MultiChoiceButtonsProps> = ({
  options,
  onSubmit,
}) => {
  const stringifiedOptions = useMemo(
    () => options.value?.map((val) => `${val}`),
    [options],
  );
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { t } = useTranslation();

  const changeCheckbox = (changedOption: string, checked: boolean) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, changedOption]);
    } else {
      const withoutOption = selectedOptions.filter(
        (option) => option !== changedOption,
      );
      setSelectedOptions(withoutOption);
    }
  };

  const submitUserMessage = () => {
    onSubmit(selectedOptions.join(', '));
  };

  return (
    <div className={classes.container}>
      <CheckboxGroup>
        {stringifiedOptions.map((op) => (
          <Checkbox
            label={op}
            checked={selectedOptions.includes(op)}
            onChange={({ checked }) => {
              changeCheckbox(op, checked);
            }}
          />
        ))}
      </CheckboxGroup>
      <Button
        size={ButtonSize.tiny}
        disabled={selectedOptions.length === 0}
        onClick={submitUserMessage}
        upgrade
        priority={ButtonPriority.secondary}
      >
        {t('ai_assistant_multi_choice_submit')}
      </Button>
    </div>
  );
};
