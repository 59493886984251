import { useEffect, useState } from 'react';

const TOP_OFFSET = 100;
const MIN_MOVING_DISTANCE = 50;

export function useStickyElementWithinContainer(
  elementRef: React.MutableRefObject<HTMLDivElement>,
  containerHeight: number,
) {
  const [elementTop, setElementTop] = useState<number>(0);

  useEffect(() => {
    const chatRect = elementRef.current.getBoundingClientRect();
    const maxTop = containerHeight - chatRect.height;
    if (maxTop > MIN_MOVING_DISTANCE) {
      const startingTop = chatRect.top + window.scrollY - TOP_OFFSET;

      const handleScroll = (e: Event) => {
        const window = e.currentTarget as Window;
        const top = window.scrollY - startingTop;
        if (top > 0 && top < maxTop) {
          setElementTop(top);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerHeight, elementRef]);

  return {
    top: elementTop,
  };
}
