import React, { FC, useState } from 'react';
import { ChoicesProps } from './interfaces';
import { MultiChoiceButtons } from './MultiChoiceButtons';
import { SingleChoiceButtons } from './SingleChoiceButtons/MultiChoiceButtons';

export const Choices: FC<ChoicesProps> = ({ choices, onSubmit }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const hideAndSubmit = (message: string) => {
    onSubmit(message);
    setSubmitted(true);
  };

  if (submitted) {
    return null;
  }

  return choices.multipleChoice ? (
    <MultiChoiceButtons
      options={choices.string || choices.numeric}
      onSubmit={hideAndSubmit}
    />
  ) : (
    <SingleChoiceButtons
      options={choices.string || choices.numeric}
      onSubmit={hideAndSubmit}
    />
  );
};
