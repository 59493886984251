import React, { FC } from 'react';
import { ChatBubbleProps } from './interfaces';
import AiIcon from '../../../../../../assets/images/Glyph.svg';
import classNames from 'classnames';
import { Avatar, AvatarSize, Text } from 'wix-ui-tpa/cssVars';
import { ThreeDotsLoader } from 'wix-ui-tpa';
import { Role } from '@wix/ambassador-forms-assistant-v1-message/types';
import Markdown from 'markdown-to-jsx';

import styles from './ChatBubble.scss';

export const ASSISTANT_CHAT_MESSAGE_HOOKS = {
  root: 'aiAssistant-chat-message',
  content: 'aiAssistant-chat-message-content',
  typing: 'aiAssistant-chat-typing',
};

export const ChatBubble: FC<ChatBubbleProps> = ({
  loading,
  message,
  additionalContent,
  creatorRole: creator,
  useMarkdown,
}) => {
  const markdownOptions = {
    overrides: {
      ol: {
        props: {
          className: styles.orderedList,
        },
      },
      ul: {
        props: {
          className: styles.unorderedList,
        },
      },
    },
  };

  return (
    <div
      data-hook={ASSISTANT_CHAT_MESSAGE_HOOKS.root}
      className={classNames({
        [styles.container]: true,
        [styles.systemMessage]: creator === Role.ASSISTANT,
        [styles.userMessage]: creator === Role.USER,
      })}
    >
      <div className={styles.creatorContainer}>
        {creator === Role.ASSISTANT ? (
          <AiIcon />
        ) : (
          <Avatar size={AvatarSize.small} />
        )}
      </div>
      <div className={styles.messageContainer}>
        {loading ? (
          <div
            className={styles.loaderContainer}
            data-hook={ASSISTANT_CHAT_MESSAGE_HOOKS.typing}
          >
            <ThreeDotsLoader className={styles.loader} />
          </div>
        ) : (
          <>
            {useMarkdown ? (
              <Text
                data-hook={ASSISTANT_CHAT_MESSAGE_HOOKS.content}
                className={styles.text}
              >
                <div className={styles.markdownWrapper}>
                  <Markdown options={markdownOptions}>{message}</Markdown>
                </div>
              </Text>
            ) : (
              <Text
                data-hook={ASSISTANT_CHAT_MESSAGE_HOOKS.content}
                className={styles.text}
              >
                {message}
              </Text>
            )}

            {additionalContent}
          </>
        )}
      </div>
    </div>
  );
};
