import React, {
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  useBi,
  useEnvironment,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import {
  FormError,
  FormValues,
  FormViewer as FormViewerComponent,
  FormViewerProps,
} from '@wix/form-viewer/widget';
import { FormHandle, FIELD_TYPES } from '@wix/form-viewer';
import { isEqual } from 'lodash';
import { ControllerProps } from '../../interfaces';
import { useStyle } from '../../hooks';
import { useExperiments } from '@wix/yoshi-flow-bm';
import { EXPERIMENTS } from '../../../../../constants/experiments';
import { FormViewerWithAI } from '../FormViewerWithAI';

export const FormViewer: FC<WidgetProps<ControllerProps>> = ({
  veloValues,
  veloOnValuesChange,
  veloOnSubmit,
  veloOnSubmitSuccess,
  veloOnSubmitFailure,
  veloIsSubmitPending,
  veloOnSubmitValidationFailure,
  veloCurrentStepId,
  veloOnCurrentStepIdChange,
  fireSubmitTrackEvent,
  ...props
}) => {
  const translation = useTranslation();
  const { i18n } = useMemo(() => translation, []);
  const [values, setValues] = useState<FormValues>(veloValues);
  const [errors, setErrors] = useState<FormError[]>([]);
  const formViewer = useRef<FormHandle>(null);
  const { style } = useStyle(props.host);
  const { experiments } = useExperiments();
  const { isEditor } = useEnvironment();
  const bi = useBi();

  const submissionTrackEventEnabled = experiments.enabled(
    EXPERIMENTS.SUBMISSION_TRACK_EVENT,
  );

  const aiAssistantEnabled = experiments.enabled(EXPERIMENTS.AI_ASSISTANT);

  const onValidate = useCallback((_errors: FormError[]) => {
    setErrors(_errors);
  }, []);

  const handleFormValuesChange = (formValues: FormValues) => {
    setValues(formValues);
    veloOnValuesChange?.(formValues);
  };

  const handleSubmitSuccess = () => {
    veloOnSubmitSuccess();

    if (submissionTrackEventEnabled) {
      fireSubmitTrackEvent(values, FIELD_TYPES.CONTACTS_SUBSCRIBE);
    }
  };

  const handleSubmitFailure = (error: unknown) => {
    veloOnSubmitFailure(error);
  };

  useEffect(() => {
    const shouldUseVeloValues =
      veloValues && !isEqual({}, veloValues) && !isEqual(values, veloValues);

    if (shouldUseVeloValues) {
      setValues(veloValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veloValues]);

  useEffect(() => {
    if (veloIsSubmitPending) {
      const submitForm = async () => {
        const submission = await formViewer.current.submit();

        if (submission === undefined) {
          veloOnSubmitValidationFailure();
        }
      };

      submitForm();
    }
  }, [veloIsSubmitPending, veloOnSubmitValidationFailure]);

  const viewerProps: FormViewerProps & React.RefAttributes<FormHandle> = {
    i18n,
    ref: formViewer,
    bi,
    formId: props.formId,
    stepId: veloCurrentStepId,
    onStepIdChange: veloOnCurrentStepIdChange,
    values,
    errors,
    onValidate,
    onChange: handleFormValuesChange,
    forcedState: [props.forceView].filter(Boolean),
    style,
    onSubmit: veloOnSubmit,
    onSubmitSuccess: handleSubmitSuccess,
    onSubmitFailure: handleSubmitFailure,
  };

  if (!isEditor && aiAssistantEnabled) {
    return (
      <FormViewerWithAI
        formViewerProps={viewerProps}
        aiAssistantProps={{
          aiAssistantState: props.aiAssistantState,
          aiAssistantService: props.aiAssistantService,
        }}
      />
    );
  }

  return <FormViewerComponent {...viewerProps} />;
};
